<template>
  <v-slide-group
    ref="vSlideGroup"
    v-resize="onResize"
    v-bind="options"
    :class="{ 'show-arrows': options.showArrows }"
  >
    <slot name="default" />
  </v-slide-group>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Shows slider arrows if the window width exceeds the container
 * or, is viewed from a desktop browser.
 *
 * All props are bound to the wrapper `v-slide-group` component.
 *
 * @displayName Slide Group
 */
export default {
  name: 'SGSlideGroup',

  data() {
    return {
      wideScreen: this.isWideScreen(),
    };
  },

  computed: {
    options() {
      const showArrows = !this.isMobile || this.wideScreen;
      return { ...this.$props, showArrows };
    },

    ...mapGetters('browser', ['isMobile']),
  },

  methods: {
    onResize() {
      this.wideScreen = this.isWideScreen();
    },

    isWideScreen() {
      return window.innerWidth > this.$vuetify.breakpoint.thresholds.xs;
    },
  },
};
</script>
