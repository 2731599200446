<template>
  <v-bottom-sheet
    v-model="open"
    class="px-0 mx-0"
    :max-width="$vuetify.breakpoint.thresholds.xs"
  >
    <template #activator="{ on }">
      <v-btn
        class="post-control-btn"
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list
      class="py-0 pb-8"
      flat
    >
      <v-list-item-group>
        <v-list-item
          class="copy-link"
          @click="copyLink"
        >
          <v-list-item-icon>
            <v-icon>
              mdi-clipboard-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            Copy link to post
          </v-list-item-title>
        </v-list-item>

        <PostPrivacyEditSheet
          v-if="post.is_editable && currentUser.is_authenticated"
          :post="post"
          @close="open = false"
        >
          <template #activator="{ on }">
            <v-list-item
              class="edit-privacy"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-account-multiple
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                Edit privacy
              </v-list-item-title>
            </v-list-item>
          </template>
        </PostPrivacyEditSheet>
      </v-list-item-group>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
import * as clipboard from 'clipboard-polyfill/text';
import { mapGetters } from 'vuex';

import { Post } from 'pwa/models';

export default {
  name: 'PostControlSheet',

  props: {
    post: {
      type: Post,
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  computed: {
    postUrl() {
      const path = this.$router.resolve(this.post.$route).href;
      return new URL(path, window.location).href;
    },
    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    async copyLink() {
      // navigator.clipboard is used if available
      await clipboard.writeText(this.postUrl);
      this.open = false;
      this.$toast('Link copied!', this.$toast.SUCCESS);
    },
  },
};
</script>
