<template>
  <div class="comment">
    <Avatar
      :user="comment.user"
      size="40"
    />

    <div class="content">
      <div>
        <router-link
          :to="comment.user.$route"
          class="username font-weight-bold"
        >
          {{ comment.user.username }}
        </router-link>
        <div
          class="post-time secondary--text text-caption"
          v-text="created"
        />
      </div>
      <div>
        <LikeButton
          v-if="computedShowLikeButton"
          :show-count="false"
          :liked="comment.liked"
          :like-action="likeAction"
          :unlike-action="unlikeAction"
        />
      </div>

      <div class="body text-body-2">
        <ReadMore>
          {{ comment.text }}
        </ReadMore>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDistanceToNow } from 'date-fns';
import { mapGetters } from 'vuex';

import LikeButton from 'pwa/components/LikeButton';
import ReadMore from 'pwa/components/ReadMore';

export default {
  name: 'Comment',

  components: {
    LikeButton,
    ReadMore,
  },

  props: {
    /**
     *
     */
    comment: {
      type: [Comment, Object],
      required: true,
    },

    /**
     * Toggles the visibility of the "like" button. By default,
     * it is only shown to authenticated users
     */
    showLikeButton: {
      type: Boolean,
      default: null,
    },

    /**
     * The function to call when the comment is liked
     */
    likeAction: {
      type: Function,
      default: () => Promise.resolve(),
    },

    /**
     * The function to call when the comment like is unliked
     */
    unlikeAction: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },

  computed: {
    created() {
      const created = new Date(this.comment.created);
      return formatDistanceToNow(created);
    },

    computedShowLikeButton() {
      if (this.showLikeButton !== null) {
        return this.showLikeButton;
      }
      return this.currentUser.is_authenticated;
    },

    ...mapGetters('auth', ['currentUser']),
  },
};
</script>

<style scoped lang="scss">
$avatar-col-width: 55px;

.comment {
  padding: 1.1em;
  display: grid;
  grid-template-columns: $avatar-col-width 1fr;
}

.content {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 36px;
}

.body {
  grid-colum: 1 / 2;
}

a.username {
  text-decoration: none;
}
</style>
