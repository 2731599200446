<template>
  <v-btn
    rounded
    v-bind="$attrs"
    class="flex-grow-1 text-body-2"
    :class="notFollowing ? 'font-weight-bold' : 'font-weight-medium'"
    :color="notFollowing ? 'primary' : 'grey'"
    :loading="pending"
    :outlined="!notFollowing"
    @click="enforceUserEnabled(toggleFollowing)"
  >
    <slot>
      <span
        v-if="user.following_status === 'not_following'"
      >
        Follow
      </span>
      <span
        v-if="user.following_status === 'follow_requested'"
      >
        Requested
      </span>
      <span
        v-if="user.following_status === 'following'"
      >
        Unfollow
      </span>
    </slot>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex';

import { User } from 'pwa/models';

export default {
  name: 'FollowButton',

  props: {
    user: {
      type: [User, Object],
      required: true,
    },

    action: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      pending: false,
    };
  },

  computed: {
    notFollowing() {
      return this.user.following_status === 'not_following';
    },
  },

  methods: {
    async toggleFollowing() {
      const { user } = this;
      const action = this.action
        || (this.notFollowing ? user.$follow : user.$unfollow).bind(user);

      this.pending = true;
      try {
        await action();
      } finally {
        this.pending = false;
      }
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>
