<template>
  <div
    :id="id"
    ref="gallery"
    class="blueimp-gallery"
    :class="{'blueimp-gallery-carousel': carousel}"
    @mousedown="mouseDownEvent = $event"
    @mouseup="handleGlobalClick"
  >
    <div class="slides" />
    <h3 class="title" />
    <p class="description" />

    <ol
      v-if="showIndicators"
      class="indicator"
    />

    <v-fade-transition>
      <div
        v-if="showControls"
        class="controls"
      >
        <v-container class="container fill-height mx-auto px-1">
          <v-btn
            v-if="!carousel"
            fab
            class="sg-close ma-2"
            icon
            dark
            @mousedown.stop
            @mouseup.stop
            @click="instance.close()"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>

          <v-row
            v-if="images && images.length > 1"
            class="d-flex no-gutters mx-0 px-0"
          >
            <v-col>
              <v-btn
                class="sg-prev"
                dark
                fab
                icon
                @mousedown.stop
                @mouseup.stop
                @click="instance.prev()"
              >
                <v-icon large>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn
                class="sg-next"
                fab
                icon
                dark
                @mousedown.stop
                @mouseup.stop
                @click="instance.next()"
              >
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-fade-transition>

    <a
      v-if="carousel"
      class="play-pause"
    />
  </div>
</template>

<script>
import 'blueimp-gallery/css/blueimp-gallery.css';
import 'blueimp-gallery/css/blueimp-gallery-indicator.css';
import 'blueimp-gallery/js/blueimp-gallery-indicator.js';
import blueimp from 'blueimp-gallery/js/blueimp-gallery.js';
import { debounce } from 'lodash-es';

export default {
  props: {
    images: {
      type: Array,
      default() {
        return [];
      },
    },

    options: {
      type: Object,
      default() {
        return {};
      },
    },

    carousel: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },

    id: {
      type: String,
      default: 'blueimp-gallery',
    },

    showIndicators: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      instance: null,
      currentSlide: null,
      mouseDownEvent: null,
      showControls: true,
    };
  },

  watch: {
    index(value) {
      if (this.carousel) {
        return;
      }
      if (value !== null) {
        this.open(value);
      } else {
        if (this.instance) {
          this.instance.destroyEventListeners();
          this.instance.close();
        }
        this.$emit('close');
      }
    },
  },

  mounted() {
    if (this.carousel) {
      this.open();
    }
  },

  destroyed() {
    if (this.instance) {
      this.instance.destroyEventListeners();
      this.instance.close();
      this.instance = null;
    }
  },

  methods: {
    open(position = 0) {
      this.showControls = true;

      const options = {
        closeOnSlideClick: false,
        toggleControlsOnReturn: true,
        thumbnailProperty: 'thumbnail_url',
        thumbnailIndicators: this.showIndicators,
        carousel: this.carousel,
        container: `#${this.id}`,
        index: position,
        onopen: () => this.$emit('onopen'),
        onopened: () => this.$emit('onopened'),
        onslide: (index, slide) => this.$emit('onslide', { index, slide }),
        onslideend: (index, slide) => this.$emit(
          'onslideend',
          { index, slide },
        ),
        onslidecomplete: (index, slide) => this.$emit(
          'onslidecomplete',
          { index, slide },
        ),
        onclose: () => this.$emit('close'),
        onclosed: () => this.$emit('onclosed'),
        urlProperty: 'url',
        ...this.options,
      };

      if (this.carousel) {
        // TODO: carousel doesn't appear used and adds complexity
        options.container = this.$el;
      }
      this.instance = blueimp(this.images, options);

      // Debounce the resize callback for iOS Safari/PWA
      this.instance.onresize = debounce(this.instance.onresize, 200);
    },

    /**
     * Toggles control visibility if the mousedown/up interval
     * if not dragging the slide.
     */
    handleGlobalClick(event) {
      const tolerance = 10;
      if (
        (Math.abs(event.clientX - this.mouseDownEvent.clientX) > tolerance)
        || (Math.abs(event.clientY - this.mouseDownEvent.clientY) > tolerance)
      ) {
        return;
      }
      this.showControls = !this.showControls;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  .container {
    position: relative;
  }

  .sg-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sg-close, .sg-next, .sg-prev {
    pointer-events: auto;
  }
}
</style>
