<template>
  <v-responsive :aspect-ratio="5/1">
    <div
      v-if="statusUpdate"
      class="blog-post"
    >
      <PostHeader
        :post="post"
      />

      <v-card-text
        class="pb-0"
        :class="textClass"
      >
        <read-more :enabled="!detailView">
          <nl2br
            tag="div"
            :text="statusUpdate.text"
          />
        </read-more>
      </v-card-text>

      <PostFooter :post="post" />
    </div>
  </v-responsive>
</template>

<script>
import PostFooter from 'pwa/components/Post/PostFooter';
import PostHeader from 'pwa/components/Post/PostHeader';
import { Post } from 'pwa/models';

export default {
  name: 'StatusUpdatePost',

  components: {
    PostFooter,
    PostHeader,
  },

  props: {
    post: {
      type: Post,
      required: true,
    },

    detailView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statusUpdate() {
      return this.post.contentObject;
    },

    textClass() {
      return this.statusUpdate.text.length < 70 ? 'text-h5' : '';
    },
  },
};
</script>
