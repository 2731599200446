<template>
  <div>
    <div
      v-if="date && showDate"
      align="center"
      class="mb-1"
    >
      <span class="date text-body-2 grey--text">
        {{ formattedDate }}
      </span>
    </div>

    <div
      class="d-flex"
      :class="{ 'justify-end': align === 'right' }"
    >
      <Avatar
        v-if="showAvatar && sender"
        :user="sender"
        class="mr-3"
        size="36"
      />

      <v-card
        max-width="75%"
        outlined
        shaped
        class="mb-2 lighten-3 message-bubble"
        :class="{ grey: shaded }"
      >
        <v-card-text align="left">
          <nl2br
            tag="p"
            :text="text"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { format, formatDistanceToNow } from 'date-fns';

/**
 * A component to render private messages
 * @displayName Message bubble
 */
export default {
  name: 'MessageBubble',

  props: {
    /**
     * The message text
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * The date the message was sent
     */
    date: {
      type: Date,
      required: false,
      default: null,
    },

    /**
     * Sender of the message
     */
    sender: {
      type: Object,
      required: true,
    },

    /**
     * Display the datetime "from" text
     */
    showDate: {
      type: Boolean,
      default: false,
    },

    /**
     * Toggles the display of the avatar
     */
    showAvatar: {
      type: Boolean,
      default: true,
    },

    /**
     * Toggles the shaded background
     */
    shaded: {
      type: Boolean,
      default: false,
    },

    /**
     * Aligns the message. `left|right`
     */
    align: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
    },
  },

  computed: {
    formattedDate() {
      const delta = new Date() - this.date;
      if (delta >= 7 * 24 * 60 * 60 * 1000) {
        return format(this.date, "P 'at' p");
      }
      let distance = formatDistanceToNow(this.date, { addSuffix: true });
      if (delta >= 25 * 60 * 60 * 1000) {
        distance += format(this.date, "' at' p");
      }
      return distance;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-bubble {
  p:last-child {
    margin-bottom: 0;
  }
}
</style>
