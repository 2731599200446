<template>
  <v-form
    class="text-area-form d-flex"
    :style="{ '--height': height }"
    @submit.prevent="submit"
  >
    <v-textarea
      ref="textarea"
      v-model="text"
      :autofocus="autofocus"
      :disabled="posting"
      :height="height"
      :placeholder="placeholder"
      dense
      hide-details
      no-resize
      outlined
      rounded
      rows="1"
      single-line
      @keydown.enter="keydownEnter"
      @input="$emit('input', $event)"
    />
    <v-btn
      type="submit"
      :height="height"
      elevation="0"
      color="primary"
      :loading="posting"
      :disabled="!text.length || formDisabled"
    >
      <v-icon
        v-show="!posting"
        size="18"
        color="white"
      >
        mdi-send
      </v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * A generic text area + form / submit button
 *
 * @displayName Text area form
 */
export default {
  name: 'TextAreaForm',

  props: {
    /**
     * Placeholder text
     */
    placeholder: {
      type: String,
      default: '',
    },

    /**
     * The text area / button height
     */
    height: {
      type: [String, Number],
      default: '40px',
    },

    /**
     * The action called when the form is submitted. Defaults to making
     * an API call to the provided comments url. The action will be called
     * with the form's `text` as an argument.
     *
     *  ```js
     *  // Example
     *  async submitAction(text) {
     *  }
     *  ```
     */
    submitAction: {
      type: Function,
      default() {
        return async (text) => text;
      },
    },

    /**
     * Disables form submission
     */
    formDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Automatically focus on input
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      text: '',
      posting: false,
    };
  },

  computed: mapGetters('browser', ['isMobile']),

  methods: {
    /**
     * Prevents newline from being unless combined with shift or
     * enter was pressed in a mobile device
     */
    async keydownEnter(event) {
      if (!event.shiftKey && !this.isMobile) {
        event.preventDefault();
        await this.submit();
      }
    },

    async submit() {
      this.text = this.text.trim();
      if (!this.text.length) {
        return;
      }
      this.posting = true;
      try {
        await this.submitAction(this.text);
        this.text = '';
        await this.$nextTick();
      } finally {
        this.posting = false;
        await this.$nextTick();
        this.$refs.textarea.focus();
      }
    },
  },
};
</script>

<style lang="scss">
$text-area-btn-radius: 28px;
$text-area-form-height: var(--height);

.text-area-form {
  font-size: 0.9rem;
  max-width: 480px;
  width: 100%;
  height: $text-area-form-height;

  .v-text-field--outlined fieldset {
    border-radius: $text-area-btn-radius 0 0 $text-area-btn-radius;
  }

  .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  textarea {
    margin-top: 0;
    padding: 6px 0;
  }

  .v-textarea textarea {
    font-size: 0.95em;
    border-radius: 0 0 0 0 ;
    height: $text-area-form-height;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  button {
    border-radius: 0 $text-area-btn-radius $text-area-btn-radius 0px !important;
    height: $text-area-form-height !important;

    .v-progress-circular {
      color: #fff;
    }
  }
}
</style>
