<template>
  <div
    v-if="stream"
    class="stream-preview"
  >
    <v-img
      width="100%"
      height="100%"
      :src="refreshedCoverURL || stream.cover_url"
      @error="$emit('error')"
      @load="$emit('load')"
    />
  </div>
</template>

<script>
import { ServiceUnavailableError } from 'pwa/exceptions';

const REFRESH_INTERVAL = 5000;

export default {
  name: 'StreamPreview',

  props: {
    /**
     * The stream model for this stream
     */
    stream: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    return {
      interval: null,
      previewLoaded: false,
      refreshedCoverURL: null,
      retriesLeft: 2,
    };
  },

  watch: {
    stream() {
      this.refreshImage();
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      this.refreshImage();
    }, REFRESH_INTERVAL);
  },

  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {
    async refreshImage() {
      if (!this.stream || !this.stream.cover_url) {
        return;
      }

      let response;

      try {
        response = await this.$store.getters['api/client'].get(
          this.stream.cover_url,
          {
            headers: { Accept: 'application/json' },
          },
        );
      } catch (e) {
        if (e instanceof ServiceUnavailableError) {
          this.retriesLeft -= 1;

          if (this.retriesLeft) {
            return;
          }
        }

        this.$emit('error');

        if (this.interval) {
          clearInterval(this.interval);
        }

        return;
      }

      this.refreshedCoverURL = response.data.location;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
