<template>
  <TextAreaForm
    placeholder="Write a comment..."
    :submit-action="submitAction.bind(this)"
  />
</template>

<script>
import TextAreaForm from 'pwa/components/TextAreaForm';
import Comment from 'pwa/models/Comment';

/**
 * A generic form used to write comments
 *
 * @displayName Comment form
 */
export default {
  name: 'CommentForm',

  components: { TextAreaForm },

  props: {
    /**
     * The object instance which is being commented on (e.g. a Post)
     */
    commentableObj: {
      type: Object,
      required: true,
    },

    /**
     * The comments URL endpoint
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * The action called when the form is submitted. Defaults to making
     * an API call to the provided comments url.
     */
    submitAction: {
      type: Function,
      async default(text) {
        const { commentableObj, url } = this;
        try {
          await Comment.dispatch('$create', { commentableObj, text, url });
        } finally {
          this.$vuetify.goTo(document.body.scrollHeight);
        }
      },
    },
  },

  data() {
    return {
      text: '',
      posting: false,
    };
  },
};
</script>
