<template>
  <SgGallery
    :id="id"
    :images="images"
    :index="index"
    :options="options"
    @close="close"
  />
</template>

<script>
import { random } from 'lodash-es';
import { mapGetters } from 'vuex';

/**
 *
 * The gallery is a component that should be placed at the top
 * level of the DOM. It is controlled via the `gallery` Vuex module.
 *
 * @displayName The Gallery
 */
export default {
  name: 'TheGallery',

  props: {
    id: {
      type: String,
      default: () => `gallery-${random(10000, 99999)}`,
    },
  },

  data() {
    return {
      // List of available options is found here:
      // https://github.com/blueimp/Gallery/blob/master/README.md#options
      options: {
        closeOnSlideClick: true,
        urlProperty: 'url', // Image object prop
      },
    };
  },

  computed: {
    ...mapGetters('gallery', ['images', 'index', 'open']),
  },

  methods: {
    close() {
      if (this.open) {
        this.$store.dispatch('gallery/close');
      }
    },
  },
};
</script>
