<template>
  <v-snackbar
    v-model="show"
    vertical
    :timeout="-1"
  >
    New update available! Reload the app to use the latest version.
    <template #action>
      <v-btn
        color="pink"
        text
        @click="reload"
      >
        Reload
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

/**
 * A "snackbar" indicator showing that the PWA has an update available
 * @displayName The App Update Snackbar
 */
export default {
  name: 'TheAppUpdateSnackbar',
  computed: {
    ...mapState({
      show: 'updateAvailable',
    }),
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>
