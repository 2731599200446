<template>
  <div>
    <div
      ref="clamp"
      class="clamp"
      :class="{ truncated: computedTruncate }"
      :style="style"
    >
      <slot />
    </div>

    <div class="my-2">
      <a
        v-if="computedTruncate"
        class="font-weight-medium more"
        @click="truncated = false"
        v-text="moreText"
      />
    </div>

    <!-- Block will appear below text when expanded -->
    <slot
      v-if="!computedTruncate"
      name="belowExpansion"
    />
  </div>
</template>

<script>
/**
 * Truncates long text to a certain height
 * @displayName Read More
 */
export default {
  name: 'ReadMore',

  props: {
    maxLines: {
      type: Number,
      default: 5,
    },
    /**
     * Sets the text in the "more" `<a>` tag
     */
    moreText: {
      type: String,
      default: 'See more',
    },

    enabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      maxHeight: null,
      truncated: false,
    };
  },

  computed: {
    computedTruncate() {
      return this.truncated && this.enabled;
    },

    style() {
      return {
        '--max-height': `${this.maxHeight}px`,
        '--max-lines': this.maxLines,
      };
    },
  },

  async mounted() {
    await this.$nextTick();
    if (!this.enabled) {
      return;
    }
    const { clamp } = this.$refs;
    const height = window.getComputedStyle(clamp).lineHeight.replace('px', '');
    this.maxHeight = this.maxLines * height;
    this.truncated = clamp.offsetHeight > this.maxHeight;
  },
};
</script>

<style lang="scss" scoped>
.truncated {
  display: -webkit-box;
  height: 100%;
  overflow: hidden;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
}
</style>
