<template>
  <v-text-field
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :label="label"
    :type="showPassword ? 'text' : 'password'"
    :value="value"
    autocomplete="current-password"
    persistent-hint
    v-bind="$attrs"
    @click:append="showPassword = !showPassword"
    @input="$emit('input', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  />
</template>

<script>
/**
 * @displayName Password field
 */
export default {
  name: 'PasswordField',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: 'Password',
    },
  },

  data() {
    return {
      showPassword: false,
    };
  },
};
</script>
