<template>
  <div
    class="like d-inline-flex justify-center align-center"
    :style="{color: color, fontSize: size}"
  >
    <v-btn
      icon
      class="mr-1"
      :disabled="disabled"
      :loading="loading"
      @click="enforceUserEnabled(clickHandler)"
    >
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </v-btn>
    <div
      v-if="showCount && likeCount"
      class="text font-weight-bold"
    >
      {{ likeCount }}
    </div>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import { mapActions } from 'vuex';

/**
 * The "like" button
 * @displayName  Like button
 */
export default {
  name: 'LikeButton',

  props: {
    /**
     * Set to true to disable like button clicks
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The "liked" state
     */
    liked: {
      type: Boolean,
      default: false,
    },

    /**
     * Toggle the display of the like count
     */
    showCount: {
      type: Boolean,
      default: true,
    },

    /**
     * The number of likes to display
     */
    likeCount: {
      type: Number,
      default: 0,
    },

    /**
     * The action to call when liking
     */

    likeAction: {
      type: Function,
      default: () => Promise.resolve(),
    },

    /**
     * The action to call when unliking
     */
    unlikeAction: {
      type: Function,
      default: () => Promise.resolve(),
    },

    /**
     * The color of the outlined icon
     */
    color: {
      type: String,
      default: colors.grey.darken2,
    },

    /**
     * The font size
     */
    size: {
      type: String,
      default: '0.8em',
    },
  },

  data: () => ({ loading: false }),

  computed: {
    icon() {
      return this.liked ? 'mdi-heart' : 'mdi-heart-outline';
    },
    iconColor() {
      return this.liked ? 'primary' : null;
    },
  },

  methods: {
    async clickHandler() {
      this.loading = true;
      const action = this.liked ? this.unlikeAction : this.likeAction;
      try {
        await action();
        await this.$nextTick();
      } finally {
        this.loading = false;
      }
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>
