<template>
  <div
    class="stream-join"
    :class="{'grey lighten-1': previewError}"
  >
    <StreamPreview
      v-show="previewLoaded"
      :stream="stream"
      class="preview"
      @load="handlePreviewLoad"
      @error="handlePreviewError"
    />

    <StreamHeader
      v-if="previewLoaded || previewError"
      class="pa-4"
      :stream="stream"
      @close="$emit('close')"
    />

    <v-btn
      v-if="previewLoaded || previewError"
      class="join-stream-btn mb-8 align-self-end"
      color="primary"
      rounded
      width="300"
      @click="enforceUserEnabled(connect)"
    >
      Join Live Stream
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import StreamHeader from '../StreamHeader';
import StreamPreview from '../StreamPreview';

export default {
  name: 'StreamJoin',

  components: {
    StreamHeader,
    StreamPreview,
  },

  props: {
    /**
     * The stream model for this stream
     */
    stream: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      previewError: false,
      previewLoaded: false,
      resolvePreviewLoading: null,
    };
  },

  mounted() {
    const previewLoadingPromise = new Promise((resolve) => {
      this.resolvePreviewLoading = resolve;
    });
    this.$store.dispatch('loadingState/activate', previewLoadingPromise);
  },

  beforeDestroy() {
    this.resolvePreviewLoading();
  },

  methods: {
    handlePreviewLoad() {
      this.previewLoaded = true;
      this.resolvePreviewLoading();
    },

    async handlePreviewError() {
      this.previewError = true;
      await this.resolvePreviewLoading();
    },

    connect() {
      this.$emit('connect');
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>

<style lang="scss" scoped>
.preview {
  position: absolute;
  height: 100%;
  width: 100%;
}

.v-btn {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
