<template>
  <v-btn
    :to="to"
    :value="value"
    active-class="primary--text"
    text
    @click="click"
  >
    <div class="nav-label">
      Messages
    </div>

    <BadgedIcon
      :acknowledged="acknowledged"
      :unread-count="unreadCount"
      icon="mdi-email"
    />
  </v-btn>
</template>

<script>
import BadgedIcon from 'pwa/components/BadgedIcon';
import Conversation from 'pwa/models/Conversation';

/**
 * Navigation message button
 * @displayName The Bottom Navigation Message Button
 */
export default {
  name: 'TheBottomNavigationMessageButton',

  components: { BadgedIcon },

  props: {
    /**
     * The vue-router route name to determine selection
     */
    value: {
      required: false,
      type: String,
      default: null,
    },

    /**
     * The router push value
     */
    to: {
      required: false,
      type: [Object, String],
      default: null,
    },
  },

  computed: {
    acknowledged: () => Conversation.getters('unreadAcknowledged'),
    unreadCount: () => Conversation.getters('unreadCount'),
  },

  methods: {
    click(event) {
      /**
       * Triggered on button click
       */
      this.$emit('click', event);
    },
  },
};
</script>
