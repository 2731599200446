<template>
  <v-row class="overlay-bottom flex-grow-0">
    <v-col class="pa-0 d-flex align-center">
      <div
        v-if="streaming"
        class="d-flex justify-space-around flex-grow-1"
        @click="handleMessageIconClick($event)"
      >
        <v-form
          v-if="showComments"
          @submit.prevent="submitComment"
        >
          <v-text-field
            v-model="text"
            placeholder="Write a comment..."
            class="pt-0 pb-0 mt-0 ml-2 mr-2 mb-0"
            prepend-inner-icon="mdi-chat"
            full-width
            dense
          />
        </v-form>

        <v-btn
          v-if="!showComments"
          :class="{ 'disable-events': !streaming }"
          icon
          color="white"
        >
          <v-icon
            color="white"
            size="28"
          >
            mdi-chat
          </v-icon>
        </v-btn>

        <v-btn
          icon
          :class="{ 'disable-events': isOwnStream }"
          @click="handleTip"
        >
          <v-icon
            color="white"
            size="28"
          >
            mdi-gift
          </v-icon>
        </v-btn>

        <v-btn
          v-if="muted && !showComments"
          icon
          @click="$emit('toggle-mute')"
        >
          <v-icon
            color="white"
            size="28"
          >
            mdi-volume-off
          </v-icon>
        </v-btn>

        <v-btn
          v-if="!muted && !showComments"
          icon
          :disabled="!streaming"
          @click="$emit('toggle-mute')"
        >
          <v-icon
            color="white"
            size="28"
          >
            mdi-volume-high
          </v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuid4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    /**
     * Whether the stream is currently muted
     */
    muted: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether comments are being shown
    */
    showComments: {
      default: false,
      type: Boolean,
    },

    /**
     * The stream model for this stream
     */
    stream: {
      default: () => {},
      type: Object,
    },

    /**
     * Whether the stream is currently active
    */
    streaming: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      text: '',
    };
  },

  computed: {
    isOwnStream() {
      return (
        this.stream
        && this.currentUser.username === this.stream.user.username
      );
    },

    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    handleTip() {
      this.openTippingDialog({
        recipients: [this.stream.user.username],
        tipsURL: this.stream.tips_url,
      });
    },

    handleMessageIconClick(event) {
      if (!event.target.classList.contains('mdi-chat')) {
        return;
      }

      this.$emit('toggle-comments');
    },

    submitComment() {
      const comment = {
        type: 'streamer.comment',
        avatar: this.currentUser.avatar,
        username: this.currentUser.username,
        user_id: this.currentUser.id,
        comment: this.text.trim(),
        user_type: this.currentUser.user_type,
        timestamp: new Date().getTime(),
        user: this.currentUser,
        uuid: uuid4(),
      };

      this.$emit('send', {
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: comment,
      });

      this.text = '';
    },

    ...mapActions('entities/tips', ['openTippingDialog']),
  },
};
</script>

<style lang="scss" scoped>
.overlay-bottom {
  height: 50px;
}

// The message icon inside the text field
.overlay-bottom::v-deep .v-text-field .mdi-chat {
  color: map-get($shades, 'white');
  cursor: pointer;
}

// The message icon when hoving over the v-text-field before it is active
.overlay-bottom::v-deep .v-text-field--placeholder:hover .mdi-chat {
  color: var(--v-primary-base);
}

// Placeholder text in the comment text field
.overlay-bottom::v-deep .v-text-field input::placeholder {
  color: map-get($shades, 'white');
}

// The bottom underline of the comment text field when it is not active
.overlay-bottom::v-deep .v-text-field .v-input__slot::before {
  border-color: map-get($shades, 'white');
}

// The bottom underline of the comment text field when hovering over it
.overlay-bottom::v-deep .v-text-field:not(.v-input--has-state):hover
.v-input__slot::before {
  border-color: var(--v-primary-base);
}

.overlay-bottom::v-deep .v-text-field input {
  color: map-get($shades, 'white');
}

.v-form {
  width: 100%;

  .v-text-field__slot {
    color: map-get($shades, 'white');
  }
}

.disable-events {
  pointer-events: none;
}
</style>

<style lang="scss">
.v-application .overlay-container textarea {
  color: map-get($shades, 'white');
}
</script>
