<template>
  <v-bottom-sheet
    v-model="open"
    v-bind="{ 'max-width': 800, ...$attrs }"
    @click:outside="$emit('click:outside')"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>

    <v-card>
      <slot name="titleBar">
        <v-card-title
          class="px-3 container-max-width mx-auto"
          style="width: 100%"
        >
          <v-container class="pa-0">
            <v-row
              class="align-center"
              dense
            >
              <v-col cols="2">
                <slot
                  name="titleLeft"
                  :close="close"
                >
                  <v-btn
                    class="close-btn"
                    icon
                    @click="close"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </slot>
              </v-col>

              <v-col
                class="title text-center text-subtitle-2"
                cols="8"
              >
                <slot name="title" />
              </v-col>

              <v-col cols="2">
                <slot
                  name="titleRight"
                  :close="close"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </slot>

      <v-card-text class="text container-max-width mx-auto px-3">
        <slot name="default" />
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'SgSheet',

  data() {
    return {
      open: false,
    };
  },

  watch: {
    open(isOpen) {
      const event = isOpen ? 'open' : 'close';
      this.$emit(event);
    },
  },

  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep .v-toolbar__content {
   padding-left: 0;
   padding-right: 0;
   width: 100%;
 }

 ::v-deep .v-toolbar__title {
   width: 100%;
   padding-left: 0;
 }
</style>
