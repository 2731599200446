<template>
  <div>
    <v-expand-transition>
      <div
        v-show="reloading"
        id="reloading-indicator"
        class="text-center mt-3 mb-5"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          width="2"
        />
      </div>
    </v-expand-transition>

    <slot name="beforePosts" />

    <div
      v-for="(post, index) in posts"
      :key="post.id"
      class="posts"
    >
      <Post
        :post="post"
        class="post"
        :class="{ 'mb-5': addMargin }"
      />

      <UserSlider
        v-if="recommendedUsers.length && index === recommendationIndex"
        class="recommendations pb-5"
        :users="recommendedUsers"
        title="Suggested Models to Follow"
      />
    </div>

    <infinite-loading
      v-if="feed"
      ref="infiniteLoading"
      :distance="400"
      :identifier="feed.id"
      class="mt-1"
      direction="bottom"
      force-use-infinite-wrapper
      @infinite="loadNext"
    />
  </div>
</template>

<script>
import { Feed, UserRecommendation } from 'pwa/models';

export default {
  name: 'TheFeed',

  props: {
    showRecommendations: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      feed: null,
      recommendationIndex: null,
      recommendedUsers: [],
      reloading: false,
      lastYPosition: 0,
      loadingStatus: 'ready',
      userToCompare: null,
      pullDetected: false,
    };
  },

  computed: {
    posts() {
      return this.feed ? this.feed.posts : [];
    },

    count() {
      return this.posts.length;
    },

    reloadRequestedAt: () => Feed.getters('reloadRequestedAt'),

    addMargin() {
      return !this.$vuetify.breakpoint.xs;
    },

    filters() {
      return this.getFilters();
    },

    serializedFilters() {
      // Workaround for use with watch, to avoid every filter access
      // triggering the watch method, even if filters unchanged.
      return JSON.stringify(this.getFilters());
    },
  },

  watch: {
    /**
     * Set the index of the post upon which the user
     * recommendation is to be based. If there are three or
     * more posts, the index will be 2 (third post). If there
     * are less, the index will be the last in the list.
     */
    count: 'setRecommendationIndex',
    loadingStatus: 'setRecommendationIndex',

    serializedFilters: {
      handler: 'setFeed',
    },

    reloadRequestedAt: 'reload',

    recommendationIndex(index) {
      const post = this.posts[index];
      const [user] = post.users_about; // Grab first
      this.userToCompare = user;
    },

    async userToCompare(user) {
      if (!user || this.recommendedUsers.length) {
        return;
      }
      this.recommendedUsers = this.getRecommendedUsersFor(user);

      if (!this.recommendedUsers.length) {
        await UserRecommendation.dispatch('$fetchUsersSimilarTo', user);
        this.recommendedUsers = this.getRecommendedUsersFor(user);
      }
    },
  },

  async created() {
    return this.setFeed();
  },

  mounted() {
    const options = { passive: true };
    this.$el.addEventListener('touchstart', this.touchStartListener, options);
    this.$el.addEventListener('touchend', this.touchEndListener, options);
    this.$el.addEventListener('touchmove', this.touchMoveListener, options);
  },

  beforeDestroy() {
    this.$el.removeEventListener('touchstart', this.touchStartListener);
    this.$el.removeEventListener('touchend', this.touchEndListener);
    this.$el.removeEventListener('touchmove', this.touchMoveListener);
  },

  methods: {
    touchStartListener(e) {
      this.pullDetected = false;
      this.lastYPosition = e.touches[0].pageY;
    },

    touchEndListener() {
      if (!this.pullDetected) {
        return;
      }
      this.reload();
    },

    touchMoveListener(e) {
      const y = e.touches[0].pageY;
      const { scrollTop } = document.scrollingElement;
      const distance = y - this.lastYPosition;
      if (scrollTop > 0 || this.reloading || distance < 75) {
        return;
      }
      this.pullDetected = true;
    },

    getRecommendedUsersFor(user) {
      const recommendations = UserRecommendation.getters('filterByUser')(user);
      if (!recommendations) {
        return [];
      }
      return recommendations.map((r) => r.designee);
    },

    setRecommendationIndex() {
      if (!this.showRecommendations || !this.count) {
        return;
      }
      if (this.count >= 3) {
        this.recommendationIndex = 2;
        return;
      }
      if (this.loadingStatus === 'complete') {
        this.recommendationIndex = this.count - 1;
      }
    },

    getFilters() {
      // Must be consistent order for filtersJSON watch.
      return Object.fromEntries(
        Feed.getters('apiFilters')
          .filter((apiFilter) => apiFilter in this.$attrs)
          .map((apiFilter) => [apiFilter, this.$attrs[apiFilter]]),
      );
    },

    async loadNext($state) {
      this.loadingStatus = 'loading';
      try {
        await this.feed.$fetchPosts();
      } catch (e) {
        this.loadingStatus = 'error';
        $state.error();
        throw e;
      }

      if (this.posts.length || this.feed.nextUrl) {
        $state.loaded();
        this.loadingStatus = 'loaded';
      }

      if (!this.feed.nextUrl) {
        $state.complete();
        this.loadingStatus = 'complete';
      }
    },

    async setFeed() {
      this.feed = await Feed.dispatch('getOrCreate', this.filters);
    },

    async reload() {
      this.reloading = true;
      const feed = await Feed.dispatch('reload', this.feed);
      this.feed = feed;
      this.reloading = false;
    },
  },
};
</script>
