<template>
  <v-badge
    color="primary"
    :content="content"
    :value="content"
    overlap
    :dot="dot"
    :bottom="dot"
    :offset-x="8"
    :offset-y="10"
    bordered
  >
    <v-icon>{{ icon }}</v-icon>
  </v-badge>
</template>

<script>
/**
 * An icon with a badge displaying a number intended to be used
 * to display notification counts.
 * @displayName Badged icon
 */
export default {

  props: {
    /**
     * A value that can be used for `v-icon` (e.g. mdi-email)
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * When true, display a subtle "dot" rather than the count
     */
    acknowledged: {
      type: [Boolean, Function],
      default: false,
    },

    /**
     * Provide a value or callable to explicitly set the unread
     * message count.
     */
    unreadCount: {
      type: [Number, Function],
      default: 0,
    },
  },
  computed: {
    content() {
      let count = this._unreadCount;
      if (this.unreadCount instanceof Function) {
        count = count();
      }
      return count < 10 ? count : '9+';
    },

    dot() {
      return this.acknowledged instanceof Function
        ? this.acknowledged() : this.acknowledged;
    },

    _unreadCount() {
      return this.unreadCount;
    },
  },
};
</script>
