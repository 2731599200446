<template>
  <v-card
    v-bind="{ tile: true, outlined: true, flat: true, ...$attrs }"
    class="feed-card"
    :class="{ 'my-5': !$vuetify.breakpoint.xs }"
  >
    <slot name="default" />
  </v-card>
</template>

<script>
export default {
  name: 'FeedCard',
};
</script>
