<template>
  <div
    class="stream"
    :class="{ ended }"
  >
    <video
      id="myvideo"
      autoplay
      playsinline
    />

    <StreamJoin
      v-if="stream && !playing"
      class="flex-grow-1"
      :stream="stream"
      @close="close()"
      @connect="connect()"
    />

    <StreamView
      v-if="stream && playing"
      :comments="comments"
      :has-connected-before="hasConnectedBefore"
      :last-tick="playing ? lastTick : null"
      :muted="muted"
      :stream="stream"
      :streaming="playing"
      @connect="connect()"
      @disconnect="disconnect()"
      @send="sendMessage"
      @toggle-mute="toggleMute()"
      @close="close()"
    />

    <StreamEnded
      v-if="ended"
      @close="close()"
    />
  </div>
</template>

<script>
import { find } from 'lodash-es';
import { ViewModule } from 'streaming-module';
import { mapGetters, mapState } from 'vuex';

import StreamEnded from './StreamEnded';
import StreamJoin from './StreamJoin';
import StreamView from './StreamView';

import { ServiceUnavailableError, StreamError } from 'pwa/exceptions';
import { Stream } from 'pwa/models';

export default {
  name: 'Stream',

  components: {
    StreamEnded,
    StreamJoin,
    StreamView,
  },

  props: {
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      comments: [],
      hasConnectedBefore: false,
      lastTick: null,
      muted: false,
      playing: false,
      pingInterval: null,
      tickInterval: null,
      viewModule: null,
      viewerToken: null,
    };
  },

  computed: {
    stream() {
      return Stream.getters('activeForUser')(this.username);
    },

    ended() {
      return (!this.stream || this.stream.ended);
    },

    ...mapGetters('auth', ['currentUser']),
    ...mapState({
      viewerStreamType: (state) => state.entities.streams.viewerStreamType,
    }),
  },

  watch: {
    /**
     * Sends pings to streamer to let them know the viewer is still watching
     * while actively viewing a stream, clears the ping interval, and
     * toggles Pusher connection.
     */
    playing() {
      if (this.playing && !this.pingInterval) {
        this.pingInterval = setInterval(() => {
          if (!this.playing && this.pingInterval) {
            clearInterval(this.pingInterval);
          }
          this.ping();
        }, 10000);
      } else if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }

      if (this.playing && !this.tickInterval) {
        this.lastTick = new Date();
        this.tickInterval = setInterval(() => {
          if (!this.playing && this.tickInterval) {
            clearInterval(this.tickInterval);
          }
          this.lastTick = new Date();
        }, 500);
      } else if (this.tickInterval) {
        clearInterval(this.tickInterval);
      }

      this.toggleChannelSubscription(this.playing);
    },

    viewerStreamType() {
      if (
        this.playing
        && this.viewModule
        && this.viewModule.streamType !== this.viewerStreamType
      ) {
        this.disconnect();
        this.connect();
      }
    },
  },

  beforeDestroy() {
    if (this.playing) {
      this.disconnect();
    }
  },

  methods: {
    async connect() {
      this.viewerToken = Math.random().toString(36).substring(7);
      const promise = this.initializeConnection();
      await this.$store.dispatch(
        'loadingState/activate',
        promise,
      );
      await promise;
    },

    async initializeConnection() {
      const client = this.$store.getters['api/client'];
      let response;
      const url = new URL(this.stream.connect_url);
      url.searchParams.append('stream_type', this.viewerStreamType);

      try {
        response = await client.get(url.href);
      } catch (e) {
        let message;
        if (e instanceof ServiceUnavailableError) {
          message = 'Temporary connection issues, please try again later';
        } else {
          message = 'Error connecting to stream, please try again later';
        }
        this.$toast(message, this.$toast.ERROR);
        throw e;
      }
      this.viewModule = new ViewModule(this.viewerStreamType);
      if (window.debugFrontend) {
        window.viewModule = this.viewModule;
      }
      this.viewModule.setConfig('serverData', response.data);
      await this.initViewModule();
    },

    initViewModule() {
      const vm = this;
      const promise = new Promise((resolve, reject) => {
        this.viewModule.init({
          remoteVideo: this.$el.querySelector('#myvideo'),
          roomName: this.stream.room_name,
          showLikes: true,
          debug: true,

          showErrorMessage(message) {
            vm.$toast(message, vm.$toast.ERROR);
          },

          showInfoMessage(message) {
            vm.$toast(message, vm.$toast.INFO);
          },

          onStreamEnd: (isError) => {
            this.playing = false;

            if (this.tickInterval) {
              clearInterval(this.tickInterval);
            }

            if (isError) {
              vm.$toast('Stream ended', vm.$toast.ERROR);
            }

            setTimeout(() => {
              Stream.dispatch('$refresh');
            }, 15500);
          },

          onStreamError: (message) => {
            reject(new StreamError(message));
            this.playing = false;

            if (this.tickInterval) {
              clearInterval(this.tickInterval);
            }

            if (message === 'Can\'t get streaming server from API...') {
              return;
            }

            vm.$toast('Live streaming error', vm.$toast.ERROR);
            Stream.dispatch('$refresh');
          },

          onTcpAudioContextStateChange: (state) => {
            switch (state) {
              case 'suspended':
                this.muted = true;
                break;
              case 'running':
                this.muted = false;
                break;
              default:
                // ignore
            }
          },

          onVideoPlaying: () => {
            // Return if no stream is set. This is hack/workaround
            if (!this.stream) {
              return;
            }

            this.playing = true;
            this.hasConnectedBefore = true;
            resolve();

            this.notifyJoined();
          },

          onCustomDataGet: (message) => {
            this.processCustomData(message);
            this.lastTick = new Date();
            this.$emit('received-custom-data', message);
          },
        });

        this.viewModule.viewStream();
      });

      return promise;
    },

    /**
     * Notify others over the websocket that we have joined the stream.
     */
    notifyJoined() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: {
          type: 'viewer.joined',
          avatar: this.currentUser.avatar,
          username: this.currentUser.username,
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Subscribe or unsubscribe from the Pusher websocket for the stream.
     */
    toggleChannelSubscription(connected) {
      const channel = `private-stream-${this.stream.id}-${this.currentUser.id}`;

      if (connected) {
        this.$store.getters['pusher/channelsClient'].subscribe(channel);
      } else {
        this.$store.getters['pusher/channelsClient'].unsubscribe(channel);
      }
    },

    /**
     * Send messages to let the streamer and server know that we are still
     * here.
     */
    ping() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['streamer'],
        data: {
          type: 'viewer.ping',
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Disconnect the stream.
     */
    disconnect() {
      if (!this.playing) {
        return;
      }

      this.notifyLeft();
      this.viewModule.stopStream(false, false);
    },

    /**
     * Close the page.
     */
    close() {
      if (this.playing) {
        this.disconnect();
      }

      this.$router.back();
    },

    /**
     * Notify others over websocket that we are leaving
     * the stream.
     */
    notifyLeft() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: {
          type: 'viewer.left',
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Relay custom data channel message to view module.
     */
    sendMessage(message) {
      this.viewModule.sendCustomMessage(message);
      this.comments.push(message.data);
    },

    /**
     * Toggle whether the stream is muted or not.
     */
    toggleMute() {
      this.muted = !this.muted;

      if (this.muted) {
        this.viewModule.muteAudio();
      } else {
        this.viewModule.unmuteAudio();
      }
    },

    /**
     * Handle incoming messages being sent over the custom data channel in the
     * websocket connection to the Janus server.
     */
    processCustomData(message) {
      if (
        message.type === 'streamer.remove_user'
        && message.user_id === this.currentUser.id
      ) {
        this.stop();
      } else if (
        message.type === 'streamer.comment'
        || message.type === 'viewer.comment') {
        const isDuplicate = find(this.comments, { uuid: message.uuid });
        if (isDuplicate) {
          return;
        }
        this.comments.push(message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stream {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.stream-join {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.ended {
  background-color: map-get($grey, 'darken-3');
}
</style>
