<template>
  <v-list-item
    class="notification"
    active-class="pink lighten-5"
    :input-value="!read"
  >
    <div class="my-4 mr-4">
      <Avatar
        v-if="actor"
        :user="actor"
        :size="avatarSize"
      />
      <v-avatar
        v-else
        :size="avatarSize"
      />
    </div>

    <v-list-item-content>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="notification-message text-body-2 text-decoration-none"
        :class="{ 'font-weight-medium': !read, 'text--secondary': read }"
        @click.prevent="clickHandler"
        v-html="notification.markup"
      />
      <div class="secondary--text text-caption">
        <span
          class="created"
          v-text="created"
        />
      </div>
    </v-list-item-content>

    <v-list-item-icon>
      <v-icon
        color="grey"
        v-text="notification.icon"
      />
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { formatDistanceToNow } from 'date-fns';

import { Notification } from 'pwa/models';

export default {
  name: 'Notification',

  props: {
    /**
     * A notification object
     */
    notification: {
      type: Notification,
      required: true,
    },
  },

  data() {
    return {
      avatarSize: 40,
    };
  },

  computed: {
    actor() {
      const { actors } = this.notification;
      const [actor] = actors;
      return actor;
    },

    created() {
      const created = new Date(this.notification.created);
      return `${formatDistanceToNow(created)} ago`;
    },

    read() {
      return this.notification.read;
    },
  },

  methods: {
    clickHandler(event) {
      if (event.target.tagName !== 'A') {
        return;
      }
      const { dataset } = event.target;

      const { contentTypeName } = dataset;
      if (contentTypeName === 'user') {
        const { username } = dataset;
        if (!username) {
          return;
        }
        this.$router.push({ name: 'profile', params: { username } });
      } else if (['album', 'video'].includes(contentTypeName)) {
        this.$router.push({
          name: 'postDetail',
          params: {
            id: dataset.objectId,
            contentType: dataset.contentType,
          },
        });
      } else {
        window.open(event.target.href);
      }
    },
  },
};
</script>
