<template>
  <div v-if="album">
    <PostHeader :post="post" />

    <div v-if="!detailView || singleImage">
      <v-carousel
        ref="carousel"
        eager
        hide-delimiter-background
        :hide-delimiters="true"
        :show-arrows="!singleImage"
        :continuous="false"
        :touch="touch"
        class="images"
        height="auto"
      >
        <div
          v-for="(image, index) in album.images"
          :key="index"
        >
          <v-carousel-item
            v-if="image.preview.url"
            :eager="index < 3"
            class="grey lighten-3 cursor-pointer carousel-item"
            :ripple="false"
            @click.stop="enforceUserEnabled(openGallery.bind(this, index))"
          >
            <sg-img
              :eager="index < 3"
              :aspect-ratio="album.feedAspectRatio"
              contain
              style="z-index: 10"
              :src="image.preview.url"
              :srcset="image.preview.srcset"
            />
            <sg-img
              :eager="index < 3"
              class="background"
              :src="image.preview.url"
            />
          </v-carousel-item>
        </div>
      </v-carousel>
    </div>

    <v-card-text
      v-if="album.text"
      class="pb-0"
    >
      <read-more :enabled="!detailView">
        <nl2br
          tag="div"
          :text="album.text"
        />
      </read-more>
    </v-card-text>

    <div v-if="detailView && !singleImage">
      <ThumbnailGrid
        :images="thumbnailImages"
        @click-image="enforceUserEnabled(
          openGallery.bind(this, $event)
        )"
      />
    </div>

    <PostFooter :post="post" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PostFooter from 'pwa/components/Post/PostFooter';
import PostHeader from 'pwa/components/Post/PostHeader';
import { convertToFullScreenResponsive } from 'pwa/utils/media';

export default {
  name: 'AlbumPost',

  components: {
    PostFooter,
    PostHeader,
  },

  props: {
    /**
     * An instance of the post model
     */
    post: {
      type: Object,
      required: true,
    },

    detailView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Override touch events in order to preserve propagation
      // to the pull-to-refresh listeners which are blocked in
      // the VWindow component. See: https://tinyurl.com/27mj9yts
      touch: {
        start: () => null,
        end: () => null,
        left: () => this.$refs.carousel.next(),
        right: () => this.$refs.carousel.prev(),
      },
    };
  },

  computed: {
    album() {
      return this.post.contentObject;
    },

    singleImage() {
      return this.album.images.length < 2;
    },

    galleryImages() {
      if (!this.album) {
        return [];
      }
      return this.album.images.map(({ preview }) => ({
        url: preview.url,
        srcset: preview.srcset,
        ...convertToFullScreenResponsive(
          preview.srcset,
          preview.width,
          preview.height,
        ),
      }));
    },

    thumbnailImages() {
      if (!this.album || !this.detailView) {
        return [];
      }
      return this.album.images.map(({ thumbnail }) => ({
        src: thumbnail.url,
        srcset: thumbnail.srcset,
      }));
    },
  },

  methods: {
    openGallery(index = 0) {
      this.$store.dispatch(
        'gallery/open',
        { images: this.galleryImages, index },
      );
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(20px) opacity(90%);
}
</style>
