<template>
  <router-link
    class="text-decoration-none"
    :to="to"
  >
    <div class="avatar-icon-container">
      <v-avatar
        :class="showIcon ? null : user.color"
        :left="left"
        :size="size"
        :style="{
          'border-width': outerRingWidth,
        }"
      >
        <v-icon
          v-if="showIcon"
          color="grey lighten-1"
          :size="size"
        >
          mdi-account-circle
        </v-icon>
        <img
          v-else
          :src="user.avatar"
          :style="`
            min-width: ${size}px;
            min-height: ${size}px;
            border-width: ${innerRingWidth};
          `"
          alt=""
          @error="showIcon = true"
        >
      </v-avatar>

      <v-icon
        v-if="user.streaming"
        :class="{
          [antennaUserTypeColor(user.user_type)]: user.streaming,
        }"
        color="white"
        :size="size <= 30 ? 8 : 12"
        :style="iconPosition"
      >
        {{ icon }}
      </v-icon>

      <v-icon
        v-if="user.present && !user.streaming"
        class="white"
        color="green accent-3"
        :size="size <= 30 ? 8 : 12"
        :style="presenceIconPosition"
        style="border: 2px solid;"
      >
        mdi-checkbox-blank-circle
      </v-icon>
    </div>
  </router-link>
</template>

<script>
import { User } from 'pwa/models';

/**
 * @displayName Avatar
 */
export default {
  props: {
    /**
     * Avatar is on left side of a component.
     *
     * This is passed through to v-avatar, and is hooked into by components
     * such as v-chip and v-btn. Set to true if you would like the left
     * prop passed to v-avatar.
     */
    left: {
      type: Boolean,
      default: false,
    },

    /**
     * User object
     */
    user: {
      type: [User, Object],
      required: true,
    },

    /**
     * Size of the avatar.
     */
    size: {
      type: [Number, String],
      default: 48,
    },

    /**
     * Router link to navigate to on click.
     */
    to: {
      type: [Object, String],
      default() {
        return this.user.$route;
      },
    },
  },

  data() {
    return {
      showIcon: false,
    };
  },

  computed: {
    outerRingWidth() {
      if (!this.user.streaming) {
        return '0';
      }

      if (this.size <= 20) {
        return '1px';
      }

      if (this.size <= 50) {
        return '2px';
      }

      return '4px';
    },

    innerRingWidth() {
      if (!this.user.streaming) {
        return '0';
      }

      if (this.size <= 20) {
        return '2px';
      }

      if (this.size <= 50) {
        return '4px';
      }

      return '6px';
    },

    icon() {
      return (
        this.size <= 30 ? 'mdi-checkbox-blank-circle-outline' : 'mdi-antenna'
      );
    },

    iconPosition() {
      return {
        bottom: '9%',
        right: this.size >= 125 ? '13%' : '8%',
        position: 'absolute',
      };
    },

    presenceIconPosition() {
      const position = 'absolute';
      let bottom;
      let right;

      if (this.size >= 125) {
        bottom = '8%';
        right = '8%';
      } else if (this.size >= 70) {
        bottom = '8%';
        right = '0%';
      } else if (this.size >= 48) {
        bottom = '3%';
        right = '-5%';
      } else {
        bottom = '3%';
        right = '-10%';
      }

      return { bottom, right, position };
    },
  },

  created() {
    User.dispatch('watchUserId', this.user.id);
  },

  beforeDestroy() {
    User.dispatch('unwatchUserId', this.user.id);
  },

  methods: {
    antennaUserTypeColor(userType) {
      const colorMap = {
        hopeful: 'deep-purple lighten-1',
        member: 'grey darken-4',
        photographer: 'orange lighten-3',
        staff: 'light-green darken-2',
        suicidegirl: 'pink lighten-2',
        sg: 'pink lighten-2',
      };

      return colorMap[userType];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-icon-container {
  display: inline-block;
  position: relative;
}

.v-avatar {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  padding: 2px;

  img {
    border-color: white;
    border-radius: 50%;
    border-style: solid;
    border-width: 0;
  }
}

.v-icon {
  border-radius: 50%;
  position: absolute;
}

.mdi-antenna {
  height: 14px;
  width: 14px;

  &::before {
    border: 2px solid map-get($shades, 'white');
    border-radius: 50%;
  }
}
</style>
