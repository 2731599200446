<template>
  <div
    v-if="video"
    class="video-post"
  >
    <PostHeader :post="post" />
    <v-responsive :aspect-ratio="video.feedAspectRatio">
      <VideoPlayer :options="options" />
    </v-responsive>

    <v-card-text
      v-if="video.text"
      class="pb-0"
    >
      <read-more :enabled="!detailView">
        <nl2br
          tag="div"
          :text="video.text"
        />
      </read-more>
    </v-card-text>

    <PostFooter :post="post" />
  </div>
</template>

<script>
import PostFooter from 'pwa/components/Post/PostFooter';
import PostHeader from 'pwa/components/Post/PostHeader';
import { Post } from 'pwa/models';

export default {
  name: 'VideoPost',

  components: {
    PostFooter,
    PostHeader,
  },

  props: {
    post: {
      type: Post,
      required: true,
    },

    detailView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    video() {
      return this.post.contentObject;
    },

    options() {
      if (!this.video) {
        return {};
      }
      return {
        fill: true,
        poster: this.video.cover_image.url,
        controls: true,
        sources: this.video.sources,
      };
    },
  },
};
</script>
