<template>
  <SgSheet @close="$emit('close')">
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-chip
          class="selection"
          label
          outlined
          small
          v-on="on"
        >
          <v-icon
            small
            left
            color="grey"
            v-text="selection.icon"
          />
          <span v-text="selection.label" />
          <v-icon
            small
            right
          >
            mdi-chevron-down
          </v-icon>
        </v-chip>
      </slot>
    </template>

    <template #title>
      Who can see your post?
    </template>

    <template #titleRight="{ close }">
      <v-btn
        class="save"
        block
        color="primary"
        small
        text
        @click="save(close)"
      >
        Done
      </v-btn>
    </template>

    <v-radio-group
      :value="privacy"
      mandatory
      @change="changeHandler"
    >
      <div
        v-for="choice in choices"
        :key="choice.privacy"
      >
        <v-radio
          :class="choice.value"
          :value="choice.value"
          class="flex-row-reverse"
        >
          <template #label>
            <div class="d-flex align-start">
              <v-icon
                class="mr-3"
                v-text="choice.icon"
              />
              <div class="d-block flex-grow-1">
                <div class="font-weight-bold">
                  {{ choice.label }}
                </div>
                <div class="text-body-2">
                  {{ choice.description }}
                </div>
              </div>
            </div>
          </template>
        </v-radio>

        <v-divider class="mb-2" />
      </div>
    </v-radio-group>
  </SgSheet>
</template>

<script>
import { Post } from 'pwa/models';

export default {
  name: 'PostPrivacyEditSheet',

  props: {
    value: {
      type: String,
      default: null,
    },

    post: {
      type: Post,
      default: null,
    },
  },

  data() {
    return {
      open: false,
      choices: Post.getters('privacyChoices'),
      privacy: null,
      selection: null,
    };
  },

  watch: {
    value(privacy) {
      this.privacy = privacy;
    },
  },

  created() {
    const privacy = this.post ? this.post.privacy : this.value;
    this.setSelection(privacy || Post.getters('defaultPrivacy'));
  },

  methods: {
    async save(closeCallback) {
      const { post, privacy } = this;
      if (post) {
        const promise = post.$update({ privacy });
        await this.$store.dispatch('loadingState/activate', promise);
      } else {
        this.setSelection(this.privacy);
      }
      closeCallback();
    },

    changeHandler(value) {
      this.privacy = value;
    },

    setSelection(value) {
      this.privacy = value;
      this.selection = this.choices.find((choice) => choice.value === value);
      this.$emit('input', value);
      Post.dispatch('setDefaultPrivacy', value);
    },
  },
};
</script>
