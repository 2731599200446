<template>
  <v-dialog
    :value="show"
    max-width="400"
    @click:outside="cancel"
    @keydown="cancel"
  >
    <v-card>
      <v-card-title class="text-h5">
        Leave Page?
      </v-card-title>

      <v-card-text>
        <slot>
          There are unsaved changes on this page. Do you wish to proceed?
          Any unsaved changes will be lost.
        </slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          outlined
          @click="cancel"
        >
          Stay on This Page
        </v-btn>

        <v-btn
          small
          color="primary darken-1"
          @click="confirm"
        >
          Leave This Page
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
 * Adds protection to components against route & location changes
 * @displayName Confirm Page Leave
 */
export default {
  name: 'ConfirmPageLeave',

  props: {
    /**
     * When `true`, listen for page unload & route changes
     */
    dirty: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show() {
      return this.showingDialog;
    },

    ...mapGetters('pageUnload', [
      'listening',
      'showingDialog',
    ]),
  },

  watch: {
    dirty(value) {
      this.toggleListener(value);
    },
  },

  mounted() {
    this.toggleListener(this.dirty);
  },

  methods: {
    toggleListener(value) {
      if (value === this.listenBeforeUnload) {
        return;
      }
      const action = value ? 'addListener' : 'removeListener';
      this[action].call();
    },

    ...mapActions('pageUnload', [
      'addListener',
      'cancel',
      'confirm',
      'removeListener',
    ]),
  },

  destroy() {
    this.removeListener();
  },
};
</script>
