<template>
  <v-card-actions>
    <v-container class="px-0 ml-3 mr-3">
      <v-row>
        <v-col
          cols="3"
          class="pa-0"
        >
          <LikeButton
            :liked="post.liked"
            :like-count="post.like_count"
            :like-action="post.$like.bind(post)"
            :unlike-action="post.$unlike.bind(post)"
          />
        </v-col>

        <v-col
          cols="3"
          class="action pa-0"
        >
          <v-btn
            class="comment-button"
            icon
            @click="enforceUserEnabled(clickComment)"
          >
            <v-icon>mdi-message-outline</v-icon>
          </v-btn>
          <div
            v-if="post.comment_count"
            class="comment-count text"
          >
            {{ post.comment_count }}
          </div>
        </v-col>

        <v-col
          cols="3"
          class="action pa-0"
        >
          <v-btn
            v-if="post.tips_url"
            class="tip-button"
            icon
            @click="enforceUserEnabled(clickTip)"
          >
            <v-icon>mdi-gift-outline</v-icon>
          </v-btn>
          <div
            v-if="post.tips_url"
            class="text"
          >
            Tip
          </div>
        </v-col>

        <v-col
          cols="3"
          class="action pa-0 justify-end"
        >
          <PostControlSheet :post="post" />
        </v-col>
      </v-row>
    </v-container>
  </v-card-actions>
</template>

<script>
import { mapActions } from 'vuex';

import LikeButton from 'pwa/components/LikeButton';

export default {
  name: 'PostFooter',

  components: {
    LikeButton,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  methods: {
    clickComment() {
      this.$router.push({ name: 'postComments', params: { id: this.post.id } });
    },

    clickTip() {
      const recipients = this.post.users_about.map(
        ({ username }) => ({ username }),
      );
      if (!recipients.length) {
        return;
      }
      this.openTippingDialog({ recipients, tipsURL: this.post.tips_url });
    },

    ...mapActions('auth', ['enforceUserEnabled']),
    ...mapActions('entities/tips', ['openTippingDialog']),
  },
};
</script>

<style lang="scss" scoped>
.action {
  display: flex;
  align-items: center;

  .text {
    font-size: 0.8em;
    color: map-get($grey, 'darken-2');
    font-weight: bold;
  }
}
</style>
