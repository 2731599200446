<template>
  <v-row class="overlay-center flex-grow-1">
    <v-col class="pa-0 d-flex flex-column">
      <div
        v-chat-scroll="{ always: false, smooth: true }"
        class="comments py-0 my-0 mx-0 px-1 flex-grow-1"
      >
        <div
          v-for="(comment, index) in comments"
          :key="index"
          :item="comment"
          class="comment d-flex"
        >
          <Avatar
            v-if="comment.user"
            :user="comment.user"
            class="flex-grow-0 mr-2"
            size="30"
            :to="{
              name: 'profile',
              params: { username: comment.user.username },
            }"
          />

          <div class="comment-details flex-grow-1">
            <p
              class="font-weight-bold pb-0 mb-0 white--text"
            >
              {{ comment.user.username }}
            </p>
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="white--text"
              v-html="comment.comment"
            />
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    /**
     * Live chat comments
     */
    comments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.comments {
  height: 1px;
  overflow-y: scroll;
  overflow-style: none;
  scrollbar-width: none;
}

.comments::scrollbar {
  display: none;
}
</style>
