import { render, staticRenderFns } from "./AlbumPost.vue?vue&type=template&id=493982ba&scoped=true"
import script from "./AlbumPost.vue?vue&type=script&lang=js"
export * from "./AlbumPost.vue?vue&type=script&lang=js"
import style0 from "./AlbumPost.vue?vue&type=style&index=0&id=493982ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493982ba",
  null
  
)

export default component.exports