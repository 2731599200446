<template>
  <v-bottom-navigation
    id="the-bottom-navigation"
    v-bind="{ app: true, color: 'primary', height: 60, ...$attrs }"
  >
    <v-btn
      id="home-btn"
      active-class="primary--text"
      exact
      value="index"
      @click="clickHome"
    >
      <div class="nav-label">
        Home
      </div>

      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn
      id="search-btn"
      active-class="primary--text"
      exact
      value="search"
      :to="{ name: 'search' }"
    >
      <div class="nav-label">
        Explore
      </div>

      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn
      id="new-post-btn"
      :to="enabled ? { name: 'newPost' } : ''"
      @click="enforceUserEnabled()"
    >
      <v-icon size="30">
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <TheNotificationButton
      value="notifications"
      :to="enabled ? { name: 'notifications' } : ''"
      @click="enforceUserEnabled(scrollTop)"
    />

    <TheBottomNavigationMessageButton
      :to="enabled ? { name: 'conversations' } : ''"
      value="messages"
      @click="enforceUserEnabled(scrollTop)"
    />
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Feed, Stream } from 'pwa/models';
/**
* @displayName The bottom navigation
*/
export default {
  name: 'TheBottomNavigation',

  computed: {
    enabled() {
      return this.currentUser.is_authenticated
        && this.currentUser.status === 'enabled';
    },
    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    /**
     * Scroll to top if already on home feed
     */
    clickHome() {
      if (!this.$route.name.startsWith('index')) {
        this.$router.push({ name: 'index' });
        return;
      }
      if (window.scrollY === 0) {
        Feed.dispatch('requestReload');
        Stream.dispatch('$refresh');
      }

      this.scrollTop();
    },

    clickPhotosets() {
      this.scrollTop();

      if (window.scrollY === 0) {
        Feed.dispatch('requestReload');
      }
    },

    scrollTop() {
      this.$vuetify.goTo(0);
    },

    ...mapActions('auth', ['$logout', 'enforceUserEnabled']),
  },
};
</script>
<style lang="scss">
#the-bottom-navigation {
  display: grid !important;
  grid-template-columns: repeat(
    5,
    minmax(auto, $container-max-width / 5)
  ) !important;
  > * {
    overflow: hidden;
  }

  .nav-label {
    font-size: 0.8em;
    letter-spacing: 0;
    margin-top: 3px;
  }
}
</style>
