<template>
  <v-container
    fluid
    class="overlay"
    :class="{
      streaming,
      ended: !streaming && hasConnectedBefore,
    }"
  >
    <StreamHeader
      :last-tick="lastTick"
      :stream="stream"
      :streaming="streaming"
      @close="$emit('close')"
    />

    <StreamEnded
      v-if="!streaming && stream && stream.ended"
      @close="$router.back()"
    />

    <StreamComments
      v-if="streaming && showComments"
      class="ml-1 mt-3"
      :comments="comments"
    />

    <v-spacer v-if="streaming && !showComments" />

    <StreamBottomControls
      v-if="streaming"
      :muted="muted"
      :show-comments="showComments"
      :stream="stream"
      :streaming="streaming"
      @send="handleSend"
      @toggle-comments="showComments = !showComments"
      @toggle-mute="$emit('toggle-mute')"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import StreamBottomControls from 'pwa/components/Stream/StreamBottomControls';
import StreamComments from 'pwa/components/Stream/StreamComments';
import StreamHeader from 'pwa/components/Stream/StreamHeader';

/**
 * Stream View
 *
 * This component contains the controls that are overlaid on the video
 * when viewing a stream. It does not manage the connection to Janus
 * directly, nor create the video tag.
 *
 * @displayName Stream View
 */
export default {
  components: {
    StreamBottomControls,
    StreamComments,
    StreamHeader,
  },

  props: {
    /**
     * Live chat comments
     */
    comments: {
      type: Array,
      default: () => [],
    },

    /**
     * Whether stream has been connected before
     */
    hasConnectedBefore: {
      type: Boolean,
      default: false,
    },

    /**
     * The stream model for this stream
     */
    stream: {
      type: Object,
      default: () => {},
    },

    /**
     * Whether the stream is currently connected and streaming
     */
    streaming: {
      type: Boolean,
      default: false,
    },

    /**
     * Date representing timestamp of last activity on connected stream
     */
    lastTick: {
      type: Date,
      default: null,
    },

    /**
     * Whether the stream is currently muted
     */
    muted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showComments: false,
      text: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    async toggleConnection() {
      this.$emit('disconnect');
    },

    handleSend(message) {
      this.$emit('send', message);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.stream-join {
  height: 100%;
  position: absolute;
  width: 100%;
  border: 1px solid yellow;
}

.overlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: map-get($grey, 'darken-3');

  &.streaming, &.ended {
    background-color: transparent;
  }
}

.overlay-top {
  align-items: center;
  height: 50px;
}
</style>
