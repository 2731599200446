<template>
  <v-btn
    rounded
    v-bind="$attrs"
    :class="isHidden ? 'font-weight-medium flex-grow-1' : 'font-weight-bold'"
    :color="isHidden ? 'grey' : 'primary'"
    :loading="pending"
    :outlined="isHidden"
    @click="enforceUserEnabled(toggleHide)"
  >
    <slot>
      <span v-if="user.is_hidden">
        Stop Hiding
      </span>
      <span v-else>
        Hide
      </span>
    </slot>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex';

import { User } from 'pwa/models';

export default {
  name: 'HideButton',

  props: {
    user: {
      type: [User, Object],
      required: true,
    },

    action: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      pending: false,
    };
  },

  computed: {
    isHidden() {
      return this.user.is_hidden;
    },
  },

  methods: {
    async toggleHide() {
      const { user } = this;
      const action = this.action
        || (this.user.is_hidden ? user.$unhide : user.$hide).bind(user);

      this.pending = true;
      try {
        await action();
      } finally {
        this.pending = false;
      }
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>
