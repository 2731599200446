<template>
  <v-container
    fluid
    class="d-flex flex-column overlay"
  >
    <v-row class="flex-grow-1">
      <v-col
        class="
          d-flex justify-center text-uppercase white--text text-h6 align-end
        "
      >
        Live stream has ended
      </v-col>
    </v-row>
    <v-row class="flex-grow-1">
      <v-col class="d-flex justify-center align-center">
        <v-btn
          color="primary"
          @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/**
 * Stream Ended
 *
 * Shown to the user when the stream has ended.
 *
 * @displayName Stream Ended
 */
export default {
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
}
</style>
