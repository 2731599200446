<template>
  <FeedCard
    v-if="post"
    class="post"
  >
    <template v-if="post.content_type_name === 'album'">
      <AlbumPost
        :post="post"
        :detail-view="detailView"
      />
    </template>

    <template v-if="post.content_type_name === 'blogpost'">
      <BlogPost
        :post="post"
        :detail-view="detailView"
      />
    </template>

    <template v-if="post.content_type_name === 'statusupdate'">
      <StatusUpdatePost
        :post="post"
        :detail-view="detailView"
      />
    </template>

    <template v-if="post.content_type_name === 'photoset'">
      <PhotosetPost
        :post="post"
        :detail-view="detailView"
      />
    </template>

    <template v-if="post.content_type_name === 'video'">
      <VideoPost
        :post="post"
        :detail-view="detailView"
      />
    </template>
  </FeedCard>
</template>

<script>
import AlbumPost from './AlbumPost';
import BlogPost from './BlogPost';
import PhotosetPost from './PhotosetPost';
import StatusUpdatePost from './StatusUpdatePost';
import VideoPost from './VideoPost';

export default {
  name: 'Post',

  components: {
    AlbumPost,
    BlogPost,
    PhotosetPost,
    StatusUpdatePost,
    VideoPost,
  },

  props: {
    /**
     * An instance of the Post model
     */
    post: {
      type: Object,
      required: true,
    },

    /**
     * When true, the full, untruncated post is rendered
     */
    detailView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
