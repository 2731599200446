<template>
  <v-img
    class="sg-img"
    eager
    v-bind="$attrs"
    :aspect-ratio="computedAspectRatio"
    :src="loading ? '' : src"
    @error="error = true"
    @load="loaded = true"
    @click="$emit('click')"
  >
    <template #placeholder>
      <div
        v-if="!loaded || loading"
        class="
          align-center
          d-flex
          fill-height
          grey
          justify-center
          lighten-3
        "
      >
        <v-img
          v-if="error && !computedLoading"
          class="placeholder"
          eager
          :src="logo"
          max-width="35%"
        />
        <v-skeleton-loader
          v-if="computedLoading"
          loading
          class="fill-height grey lighten-3"
          type="image"
          width="100%"
          height="100%"
          absolute
          top
          left
        />
      </div>
    </template>
  </v-img>
</template>

<script>
import logo from '@/../suicidegirls/static/img/logo-solo.svg';

export default {
  name: 'SgImg',

  props: {
    aspectRatio: {
      type: [String, Number],
      default: null,
    },

    src: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      error: false,
      loaded: false,
      logo,
    };
  },

  computed: {
    computedAspectRatio() {
      const { aspectRatio } = this;
      if (aspectRatio) {
        return aspectRatio;
      }
      if ('width' in this.$attrs || 'height' in this.$attrs) {
        return null;
      }

      return 5 / 3;
    },

    computedLoading() {
      // Use prop if set
      if (this.loading !== null) {
        return this.loading;
      }
      return !this.error;
    },
  },

  async mounted() {
    // Let the loading state display before deciding
    // showing the placeholder for empty images.
    await new Promise((r) => setTimeout(r, 500));
    this.error = !this.src;
  },
};
</script>
