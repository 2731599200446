<template>
  <v-container
    v-if="user"
    class="post-header"
  >
    <v-row
      class="d-flex align-start flex-nowrap"
      no-gutters
    >
      <v-col class="flex-grow-0 mr-2 align-self-center">
        <div
          class="flex-shrink-1 align-center"
          mr-3
        >
          <Avatar :user="user" />
        </div>
      </v-col>

      <v-col class="flex-grow-1 align-start pt-1">
        <v-container class="ma-0 pa-0">
          <v-row
            class="d-flex align-start flex-nowrap ma-0"
            no-gutters
          >
            <v-col class="flex-grow-1">
              <router-link
                class="username text-decoration-none font-weight-bold"
                :class="user.textColor"
                :to="user.$route || ''"
              >
                {{ user.username }}
              </router-link>
            </v-col>
            <v-col class="text-right">
              <router-link
                :to="toDetail"
                class="text-decoration-none secondary--text
                font-weight-medium text-caption"
              >
                {{ created }}
              </router-link>
            </v-col>
          </v-row>

          <v-row
            class="ma-0 align-start"
            no-gutters
          >
            <v-col>
              <PostPrivacyInfoSheet
                class="d-inline-block"
                :post="post"
              />

              <v-icon
                size="2"
              >
                mdi-checkbox-blank-circle
              </v-icon>

              <slot name="content-type">
                <router-link
                  class="text-caption font-weight-medium text-decoration-none"
                  :to="toDetail"
                >
                  <span class="content-type-display secondary--text">
                    {{ post.content_type_display }}
                  </span>
                </router-link>
              </slot>
              <slot name="append-content-type" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDistanceToNow } from 'date-fns';

export default {
  name: 'PostHeader',

  props: {
    /**
     * Post instance
     */
    post: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      toDetail: { name: 'postDetail', params: { id: this.post.id } },
    };
  },

  computed: {
    user() {
      return this.post
        && this.post.users_about
        && this.post.users_about.length > 0
        ? this.post.users_about[0] : {};
    },

    created() {
      const created = new Date(this.post.created);
      return `${formatDistanceToNow(created)} ago`;
    },
  },

  methods: {
    formatDistanceToNow,
  },
};
</script>

<style lang="scss" scoped>
.post-header {
  line-height: 1;
}
</style>
