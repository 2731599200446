<template>
  <div>
    <v-icon
      class="privacy-icon"
      small
      @click="open = true"
      v-text="privacy.icon"
    />

    <v-bottom-sheet
      v-model="open"
      max-width="800"
    >
      <v-card>
        <v-card-title class="px-3 container-max-width mx-auto">
          <v-container class="pa-0">
            <v-row
              class="align-center"
              dense
            >
              <v-col cols="2">
                <v-btn
                  class="close-btn"
                  icon
                  @click="open = false"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="8">
                <div class="text-subtitle-1 font-weight-medium text-center">
                  Who can see this post?
                </div>
              </v-col>

              <v-col cols="2" />
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="text container-max-width mx-auto px-3 text-center">
          <v-icon
            size="50"
            v-text="privacy.icon"
          />
          <div
            class="my-2"
            v-text="privacyDescription"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { Post } from 'pwa/models';

export default {
  name: 'PostPrivacyInfoSheet',

  props: {
    post: {
      type: [Post, Object],
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  computed: {
    privacy() {
      return Post.getters('privacyChoices')
        .find(({ value }) => value === this.post.privacy);
    },

    privacyDescription() {
      if (this.post.privacy === 'followers') {
        const currentUser = this.$store.getters['auth/currentUser'];
        const index = this.post.users_about
          .findIndex(({ id }) => id === currentUser.id);
        if (index === -1) {
          // TODO: implement multi-user display as part of:
          // https://github.com/suicidegirlsdev/suicidegirls/issues/2018
          const { username } = this.post.users_about[0];
          return `Only members who follow ${username}`;
        }
      }
      return this.privacy.description;
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep .v-toolbar__content {
   padding-left: 0;
   padding-right: 0;
   width: 100%;
 }

 ::v-deep .v-toolbar__title {
   width: 100%;
   padding-left: 0;
 }
</style>
