<template>
  <v-row class="overlay-top flex-grow-0">
    <v-col
      class="d-flex pa-0"
      cols="6"
    >
      <div
        v-if="stream"
        class="d-flex"
      >
        <Avatar
          :user="stream.user"
          class="flex-grow-0 mx-3"
          size="48"
        />

        <div style="z-index: 1;">
          <router-link
            class="
              white--text font-weight-bold text-capitalize text-decoration-none
            "
            :to="stream.user.$route"
          >
            {{ stream.user.username }}
          </router-link>

          <div
            v-if="!streaming && stream.viewer_count"
            class="grey--text text--lighten-1 text-caption"
          >
            {{ stream.viewer_count }}
            {{ 'Viewer' | pluralize(stream.viewer_count) }}
          </div>
        </div>
      </div>
    </v-col>

    <v-col
      class="d-flex pa-0 justify-end align-center"
      cols="6"
    >
      <StreamTimer
        v-show="startUnixTime && lastTickUnixTime"
        :streaming="streaming"
        :start="startUnixTime"
        :last-tick="lastTickUnixTime"
        class="d-flex align-center white--text mr-2 mt-1"
      />

      <v-btn
        class="mr-2"
        icon
        @click="$emit('close')"
      >
        <v-icon
          color="white"
          size="32"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import StreamTimer from 'main/components/StreamTimer';

export default {
  components: {
    StreamTimer,
  },

  props: {
    /**
     * Date representing timestamp of last activity on connected stream
     */
    lastTick: {
      type: Date,
      default: null,
    },

    /**
     * The stream model for this stream
     */
    stream: {
      default: () => {},
      type: Object,
    },

    /**
     * Whether the stream is currently connected and streaming
     */
    streaming: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    startUnixTime() {
      if (!this.stream || !this.stream.created) {
        return null;
      }

      return new Date(this.stream.created).getTime() / 1000;
    },

    lastTickUnixTime() {
      if (!this.lastTick) {
        return null;
      }

      return this.lastTick.getTime() / 1000;
    },
  },
};
</script>
