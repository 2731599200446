<template>
  <v-list-item
    v-if="other_participants.length"
    three-line
    class="conversation"
    :input-value="!read"
    active-class="pink--text text--lighten-3"
    @click="click"
  >
    <div class="mt-0 mr-4 mb-4 ml-0">
      <Avatar
        :user="other_participants[0]"
        size="40"
      />
    </div>

    <v-list-item-content class="align-start">
      <v-list-item-title class="d-flex mb-0">
        <a
          color="primary"
          class="font-weight-bold flex-grow-1"
        >
          {{ other_participants[0].username }}
        </a>
        <div
          class="created align-end text-body-2 grey--text text--darken-1"
          :class="{ 'font-weight-bold': !read }"
        >
          {{ dateFormat(latestActivity, 'P') }}
        </div>
      </v-list-item-title>
      <v-list-item-subtitle
        class="pt-0 mt-0 text-body-2"
        :class="{
          'font-weight-medium': !read,
          'font-weight-light': read,
        }"
      >
        {{ text }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { format as dateFormat } from 'date-fns';
/**
 * Conversation list item
 * @displayName Conversation list item
 */
export default {
  name: 'ConversationListItem',

  props: {
    /**
     * List of participant objects in this conversations
     */
    participants: {
      type: Array,
      required: true,
    },

    /**
     * The message text used to preview (gets truncated)
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * The time at which the last message was sent
     */
    latestActivity: {
      type: Date,
      required: true,
    },

    /**
     * router.push parameters
     */
    to: {
      type: Object,
      required: false,
      default: null,
    },

    /**
     * Set to true if the conversation has been read
     */
    read: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    other_participants() {
      const currentUser = this.$store.getters['auth/currentUser'];
      return this.participants.filter((p) => p.id !== currentUser.id);
    },
  },

  methods: {
    dateFormat,

    click() {
      if (!this.to) {
        return;
      }
      this.$router.push(this.to);
    },
  },
};
</script>
