<template>
  <div>
    <v-chip
      pill
      close
      close-icon="mdi-refresh"
      color="error"
      @click:close="trigger"
    >
      An error has occurred
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    trigger: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
