<template>
  <div v-if="photoset">
    <PostHeader :post="post">
      <template #append-content-type>
        <span
          v-if="photoset.name"
          class="
            photoset-name
            text-caption
            font-weight-bold
            grey--text
            text--lighten-1
          "
        >
          “{{ photoset.name }}”
        </span>
        <div v-if="photoset.photographer_name">
          <a
            :href="photographerHref"
            :target="photographerUrlTarget"
          >
            <v-icon small>
              mdi-camera
            </v-icon>
          </a>
          <span
            class="
              photographer-name
              text-caption
              font-weight-bold
              grey--text
              text--lighten-1
              text-capitalize
            "
          >
            <a
              v-if="photoset.photographer_name"
              :href="photographerHref"
              class="grey--text text--lighten-1"
              :target="photographerUrlTarget"
            >
              {{ photoset.photographer_name }}
            </a>
          </span>
        </div>
      </template>
    </PostHeader>

    <div class="cover-image-container">
      <sg-img
        eager
        :aspect-ratio="photoset.feedAspectRatio"
        :src="photoset.cover_image.url"
        class="cover-image cursor-pointer mb-1"
        @click="enforceUserEnabled(clickCover)"
      />
      <v-chip
        v-if="!detailView || !currentUser.is_authenticated"
        class="image-count py-1 ma-4"
        color="primary"
        small
        text-color="white"
        @click="enforceUserEnabled(clickChip)"
      >
        <v-avatar>
          <v-icon small>
            mdi-image-multiple
          </v-icon>
        </v-avatar>
        <span class="font-weight-medium px-1">
          {{ photoset.image_count }}
        </span>
      </v-chip>
    </div>

    <ThumbnailGrid
      :images="thumbnailImages"
      @click-image="enforceUserEnabled(
        clickThumbnail.bind(this, $event)
      )"
    />

    <v-card-text
      v-if="detailView && photoset.text"
      class="text"
      v-text="photoset.text"
    />

    <PostFooter :post="post" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PostFooter from 'pwa/components/Post/PostFooter';
import PostHeader from 'pwa/components/Post/PostHeader';
import { convertToFullScreenResponsive } from 'pwa/utils/media';

export default {
  name: 'PhotosetPost',

  components: {
    PostFooter,
    PostHeader,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },

    detailView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      galleryIndex: null,
    };
  },

  computed: {
    photoset() {
      return this.post.contentObject;
    },

    photographerUrl() {
      const url = this.photoset.photographer_url;
      try {
        return new URL(url);
      } catch (e) {
        return null;
      }
    },

    photographerHref() {
      return this.photographerUrl ? this.photographerUrl.href : '';
    },

    photographerUrlTarget() {
      let isExternal = false;
      if (this.photographerUrl) {
        isExternal = this.photographerUrl.host !== window.location.host;
      }
      return isExternal ? '_blank' : '_self';
    },

    images() {
      if (!this.detailView) {
        return this.getPreviewImages();
      }
      return this.photoset.images;
    },

    galleryImages() {
      if (!this.photoset) {
        return [];
      }
      return this.photoset.images.map(({ preview }) => ({
        url: preview.url,
        srcset: preview.srcset,
        ...convertToFullScreenResponsive(
          preview.srcset,
          preview.width,
          preview.height,
        ),
      }));
    },

    thumbnailImages() {
      if (!this.images) {
        return [];
      }
      return this.images.map(({ thumbnail }) => ({
        src: thumbnail.url,
        srcset: thumbnail.srcset,
      }));
    },

    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    clickChip() {
      this.$router.push(this.post.$route);
    },

    clickCover() {
      this.openGallery(0);
    },

    clickThumbnail(index) {
      if (!this.detailView) {
        this.$router.push(this.post.$route);
        return;
      }
      this.openGallery(index);
    },

    /**
     * Get an even sample of 3 images to preview
     */
    getPreviewImages() {
      const count = this.photoset.images.length;
      if (count <= 3) {
        return this.photoset.images;
      }
      return [
        this.photoset.images[0],
        this.photoset.images[Math.floor(count / 2)],
        this.photoset.images[count - 1],
      ];
    },

    openGallery(index) {
      this.$store.dispatch(
        'gallery/open',
        { images: this.galleryImages, index },
      );
    },

    ...mapActions('auth', ['enforceUserEnabled']),
  },
};
</script>

<style lang="scss" scoped>
.cover-image-container {
  position: relative;

  .image-count {
    opacity: 0.85;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
